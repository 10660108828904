<template>
  <div class="honor">
    <div class="title">
      <span class="chinese">荣誉证书 </span>
      <span class="english">/certificate of honor</span>
    </div>
    <carousel-3d
        :autoplay="true"
        :autoplayTimeout="3000"
        :perspective="35"
        :display="5"
        :animationSpeed="1000"
        :width="486"
        :height="307"
        :controlsVisible="true"
        loop
    >
      <slide v-for="(item, i) in imgList" :index="i" :key="i">
        <template slot-scope="obj">
          <img :src="item.path" @click="showImgViewer(item.value)"/>
        </template>
      </slide>
    </carousel-3d>

    <image-viewer
        v-if="imgViewerVisible"
        :on-close="closeImgViewer"
        :url-list="checkImgList"
        :initialIndex="initialIndex"
    />
  </div>
</template>

<script>
export default {
  name: "honor",
  components: {
    "image-viewer": () => import("@/components/ImageViewer/imageViewer.vue")
  },
  data() {
    return {
      imgList: [
        {value: 0, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor1.png'},
        {value: 1, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor2.png'},
        {value: 2, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor3.png'},
        {value: 3, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor4.png'},
        {value: 4, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor5.png'},
        {value: 5, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor6.png'},
        {value: 6, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor7.png'},
        {value: 7, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor8.png'},
        {value: 8, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor9.png'},
        {value: 9, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor10.png'},
        {value: 10, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor11.png'},
        {value: 11, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor12.png'},
        {value: 12, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor13.png'},
        {value: 13, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor14.png'},
        {value: 14, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor15.png'},
        {value: 15, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor16.png'},
        {value: 16, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor17.png'},
        {value: 17, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor18.png'},
        {value: 18, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor19.png'},
        {value: 19, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor20.png'},
        {value: 20, path: 'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor21.png'},
      ],
      checkImgList: [
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor1.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor2.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor3.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor4.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor5.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor6.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor7.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor8.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor9.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor10.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor11.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor12.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor13.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor14.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor15.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor16.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor17.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor18.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor19.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor20.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/tianhua-website/honor21.png',
      ],
      imgViewerVisible: false,
      initialIndex: null
    }
  },
  methods: {
    showImgViewer(value) {
      this.initialIndex = value;
      this.imgViewerVisible = true;
      const m = e => {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", m, false); // 禁止页面滑动
    },
    closeImgViewer() {
      this.imgViewerVisible = false;
      const m = e => {
        e.preventDefault();
      };
      document.body.style.overflow = "auto";
      document.removeEventListener("touchmove", m, true);
    }
  }
}
</script>

<style lang="scss" scoped>

.honor {
  width: 100%;
  height: 700px;
  position: relative;
  background: url("~@/assets/index/honor-bg.png") no-repeat;
  box-sizing: border-box;
  background-size: auto 100%;
  padding: 72px 210px;

  .title {
    font-size: 42px;
    font-weight: 400;
    color: #333333;
    line-height: 49px;
    margin-bottom: 89px;

    .english {
      font-size: 30px;
    }
  }
}

::v-deep {
  // 点击展示大图样式
  .el-image-viewer__img {
    width: 500px !important;
    height: auto !important;
  }

  .el-image-viewer__close {
    opacity: 1;
    top: 100px;
  }

  .el-image-viewer__prev {
    left: 500px;
    opacity: 1;
  }

  .el-image-viewer__next {
    right: 500px;
    opacity: 1;
  }
}

</style>
