<template>
  <div class="">
    <!--头部banner部分-->
    <div class="banner">
      <swiper :options="swiperOption" ref="mySwiper" style="min-width: 1200px" class="swiper_content">
        <swiper-slide
            class="swiper-slide swiper-container-3d"
            v-for="(banner, index) in bannerList"
            :key="index"
        >
          <img class="banner-img" :src="banner"/>
          <template v-if="index === 0">
            <div class="title1 a-bounceinT">多层面的整理解决方案能力</div>
            <div class="title2 a-bounceinL">全面支撑政府 企业数字化转型</div>
          </template>
          <template v-if="index === 1">
            <div class="title3">诚信务实 开拓创新</div>
            <div class="title4">合丨作丨发丨展丨服丨 务丨社 丨会</div>
          </template>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <ProductCase></ProductCase>
    <Honor></Honor>
  </div>
</template>

<script>
import ProductCase from "@/views/business-a/page-a/components/product-case.vue";
import Honor from "@/views/business-a/page-a/components/honor.vue";
export default {
  name: "home",
  components: {
    ProductCase,
    Honor
  },
  computed: {},
  data() {
    return {
      bannerList: [
        'http://imagepub.chinau8.com/tianhua_jinhua/tianhua-website/banner1.png',
        'http://imagepub.chinau8.com/tianhua_jinhua/tianhua-website/banner2.png',
      ],
      swiperOption: {
        loop: true,
        speed: 500,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        // autoplay:false,
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
      },
    };
  },
  created() {
  },
  destroyed() {
  },
  mounted() {
  },
  methods: {},
  beforeDestroy() {
  },
};
</script>
<style lang="scss" scoped>
::v-deep .swiper-pagination .swiper-pagination-bullet {
  width: .26rem;
  height: .13rem;
  background: rgba(255, 255, 255, .5);
  border-radius: 0.3rem;
  margin-left: 14px;
}

/*显示当前活动块的图片*/
::v-deep .swiper-pagination .swiper-pagination-bullet-active {
  background: rgba(255, 255, 255, 1);
  border-radius: 0.3rem;
}

::v-deep .swiper-pagination {
  bottom: 70px;
  left: 5rem;
}

.banner {
  width: 1920px;
  height: 783px;
  background: #fff;
  position: relative;

  .banner-img {
    width: 1920px;
    height: 783px;
    z-index: -99;
  }

  .a-bounceinL {
    animation: bounceinL 2s ease-out backwards;
    animation-delay: 1s;
  }

  .a-bounceinR {
    animation: bounceinR 2s ease-out backwards;
    animation-delay: 4s;
  }

  .title1 {
    width: 1300px;
    position: absolute;
    top: 279px;
    left: 50%;
    margin-left: -650px;
    z-index: 999;
    font-size: 100px;
    font-weight: bold;
    color: #E8F3FF;
    line-height: 120px;
    letter-spacing: 5px;
  }

  .title2 {
    width: 900px;
    position: absolute;
    top: 407px;
    left: 50%;
    margin-left: -450px;
    z-index: 999;
    font-size: 62px;
    color: #E8F3FF;
    line-height: 120px;
    letter-spacing: 5px;
  }

  .title3 {
    width: 1316px;
    position: absolute;
    top: 300px;
    left: 50%;
    margin-left: -658px;
    z-index: 999;
    font-size: 110px;
    font-weight: bold;
    color: #E8F3FF;
    line-height: 120px;
    letter-spacing: 5px;
  }

  .title4 {
    width: 900px;
    position: absolute;
    top: 420px;
    left: 50%;
    margin-left: -450px;
    z-index: 999;
    font-size: 48px;
    color: #E8F3FF;
    line-height: 120px;
  }
}

</style>
