<template>
  <div class="product-container">
    <div class="title">
      <span class="chinese">产品案例 </span>
      <span class="english">/Product case</span>
    </div>
    <div class="content flex">
      <div class="left-box flex flex-wrap">
        <div class="item" v-for="(item,index) in list" :class="'item' + (index + 1)"
             :style="{background:`url(${item.bg}) no-repeat`}"
             @click="onOpenPage(item.id)"
        >
          <div class="name">
            {{ item.name }}
          </div>
          <img class="icon" :src="item.icon" alt=""/>
        </div>
      </div>

      <div class="center-box" @click="onOpenPage(5)">
        <div class="item">
          <div class="name">
            园区公共服务平台
          </div>
          <img class="icon" src="~@/assets/index/icon-black.png" alt=""/>
        </div>
      </div>

      <div class="right-box" @click="onOpenPage(6)">
        <div class="item">
          <div class="name">
            智能酒店
          </div>
          <img class="icon" src="~@/assets/index/icon-white.png" alt=""/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          name: "未来乡村系统",
          bg: require('@/assets/index/item1-bg.png'),
          icon: require('@/assets/index/icon-black.png')
        },
        {
          id: 2,
          name: "政务管理系统",
          bg: require('@/assets/index/item2-bg.png'),
          icon: require('@/assets/index/icon-white.png')
        },
        {
          id: 3,
          name: "社区服务管理系统",
          bg: require('@/assets/index/item3-bg.png'),
          icon: require('@/assets/index/icon-white.png')
        },
        {
          id: 4,
          name: "在线考试系统",
          bg: require('@/assets/index/item4-bg.png'),
          icon: require('@/assets/index/icon-black.png')
        }
      ]
    }
  },
  methods: {
    onOpenPage(id) {
      this.$store.commit('user/activeState', id)
      this.$router.push({name: `product`})
    }
  }
}
</script>

<style lang="scss" scoped>
.product-container {
  width: 100%;
  height: 840px;
  background: url("http://imagepub.chinau8.com/tianhua_jinhua/tianhua-website/producu-case-bg.png") no-repeat;
  box-sizing: border-box;
  background-size: auto 100%;
  padding: 80px 0 66px;

  .title {
    font-size: 42px;
    font-weight: 400;
    color: #333333;
    line-height: 49px;
    margin-bottom: 50px;

    .chinese {

    }

    .english {
      font-size: 30px;
    }
  }

  .content {
    padding: 0 210px;
    height: 582px;

    .left-box {
      width: 580px;
      height: 580px;
      position: relative;
      z-index: 99;

      .item {
        box-shadow: 0px 0px 20px 0px rgba(67, 67, 67, 0.12);
        padding: 40px;
        width: 50%;
        height: 50%;
        box-sizing: border-box;
        background-size: cover !important;
        text-align: left;
        cursor: pointer;

        .icon {
          width: 40px;
        }
      }

      .item1, .item4 {
        font-size: 20px;
        font-weight: bold;
        line-height: 23px;
      }

      .item2, .item3 {
        font-size: 20px;
        font-weight: bold;
        line-height: 23px;

        .name {
          color: #FFFFFF;
          margin-bottom: 20px;
        }
      }

    }

    .center-box {
      width: 570px;
      height: 580px;
      background: url("~@/assets/index/item5-bg.png") no-repeat;
      background-size: cover;
      opacity: 1;
      cursor: pointer;
    }

    .item {
      padding: 72px;
      text-align: left;

      .name {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
        margin-bottom: 20px;
      }

      .icon {
        width: 40px;
      }
    }

    .right-box {
      width: 350px;
      height: 580px;
      background: url("~@/assets/index/item6-bg.png") no-repeat;
      background-size: cover;
      cursor: pointer;

      .item {
        padding: 72px 52px;
        text-align: left;

        .name {
          color: #fff;
        }

      }
    }

  }
}
</style>
